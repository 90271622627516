import React, { useContext, useEffect, useState } from 'react'

const AppContext = React.createContext<
  ReturnType<typeof useAppContextProvider>
>({} as any)

const useAppContextProvider = (data) => {
  const [_data, setAppData] = useState<{ menus: any; logoEvent: any }>({
    logoEvent: { image: '', name: '' },
    ...data,
  })

  useEffect(() => {
    setAppData({ _data, ...data })
  }, [data])

  return {
    data: _data,
    setAppData,
  }
}

export const AppContextProvider = ({ data, children }) => {
  const propData = useAppContextProvider(data)
  return <AppContext.Provider value={propData}>{children}</AppContext.Provider>
}

export const useAppContext = () => {
  return useContext(AppContext)
}
